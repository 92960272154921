import React from "react";

const ServicesMain = () => {
  return (
    <div className="w-full min-h-[130vh] pt-[100px] pb-[80px]">
      <div className="main-container">
        <h3 className="text-dark text-[50px]  mb-4">Services.</h3>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="flex flex-col gap-y-[150px]">
            <p className="text-[22px] text-dark capitalize">
              Business
              <br />
              Improvement
            </p>
            <p className="text-[22px] text-dark capitalize">
              Crisis management
              <br />& executive support
            </p>
            <p className="text-[22px] text-dark capitalize">
              Industries <br />
            </p>
            <p className="text-[22px] text-dark capitalize">
              Specialized <br />
              Advisory
              <br />
              Services
            </p>
          </div>
          <div>
            <p className="text-[15px] text-gray mb-[40px]">
              Our Business architect team will help you define your Goals,
              Objectives and Strategies by understand the pressures,
              opportunities, and constraints, to ensure the company is on the
              right path. Hereafter we will create a plan following your budget
              and timeline to implement those critical changes.
              <br /> <br />
              <a href="/contact" className="underline text-primary">Contact us now</a> for a
              free phone consultation. <br /> <br />
              When a company faces an unforeseen crisis, billions of dollars in
              market value can hang in the balance. Autopilot has helped manage
              some of the most high-profile crises for clients across industry
              groups and geographies. By helping clients prepare for the
              unexpected and respond quickly when trouble arises, Autopilot
              ultimately protects a company’s reputation and its permission to
              operate.
            </p>
            <ul className="list-disc list-outside text-[15px] text-gray mb-[40px] capitalize flex flex-col gap-y-1">
              <li>IT & Telecom</li>
              <li>Communications & Entertainment</li>
              <li>IMarketing & Communications</li>
              <li>IFinancial & Professional Services</li>
              <li>Construction and Architecture</li>
              <li>Design & Engineering </li>
              <li>Energy</li>
              <li>Resources & Infrastructure</li>
            </ul>
            <p className="text-[15px] text-gray">
              Specialized consulting services to help companies optimize
              operations, reduce risk, make informed decisions, and enhance
              value.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesMain;
