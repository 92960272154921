import React from "react";
import Layout from "../../layout/Layout";
import ServicesMain from "./ServicesMain";

const Index = ({ location }) => {
  return (
    <Layout location={location}>
      <ServicesMain />
    </Layout>
  );
};

export default Index;
